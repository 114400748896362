function Solve() {
  return <div className="grid grid-cols-1 md:grid-cols-4">
    <div className="text-xl text-center p-8 md:col-start-2 col-span-2">
      <h1>
        Coming later (maybe not) ... a tool where you can upload a photo of a real chess board for analysis!
      </h1>
    </div>
    
  </div>;
}

export default Solve;
